<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-margin" v-if="!isMobile">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left " cols="12">
          <h1 class="index-margin__title">Reporte de nómina</h1>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <template v-for="data in listActionMargin">
              <v-col
                v-if="data.permisson"
                class="pl-0 pr-6 index-margin__col-card"
                :key="data.id"
                cols="2"
              >
                <v-card outlined elevation="0" class="index-margin__card">
                  <v-card-title>
                    <v-layout justify-center>
                      <img width="100" :src="data.icon" />
                    </v-layout>
                  </v-card-title>
                  <v-card-text style="height: 80px" class="text-left">
                    <p class="title-margin-action">{{ data.title }}</p>
                    <p class="text-margin-action">{{ data.description }}</p>
                  </v-card-text>
                  <v-card-actions class="pt-0 pa-xl-1 pt-xl-5 mt-0 mb-3 mt-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="go(data)"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                        class="pa-xl-0 mt-10"
                      >
                        {{ data.btnName }}
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-margin" v-else>
      <v-row justify="center" class="ml-0 mr-0 px-3 my-4 pb-8">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Información detallada de su nómina
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-0">
          <template v-for="data in listActionMargin">
            <v-card
              v-if="data.permisson && data.mobileVersion"
              :key="data.id"
              class="requests-card-margin pb-1 pt-1"
            >
              <v-col
                style="text-align: initial"
                class="pl-3 pr-0 d-flex align-center"
                :key="data.id"
                cols="3"
              >
                <v-img width="55" :src="data.icon"></v-img>
              </v-col>
              <v-col
                style="text-align: initial"
                :key="data.id"
                cols="6"
                class="d-flex align-center"
              >
                <p class="title-margin-action pb-0 mb-0 pt-1">
                  {{ data.title }}
                </p>
              </v-col>
              <v-col :key="data.id" cols="3" class="d-flex align-center">
                <v-btn
                  small
                  rounded
                  dark
                  @click="go(data)"
                  color="#466be3"
                  class="mt-3"
                  >ver</v-btn
                >
              </v-col>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </div>

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      isMobile: false,
      origin: window.location.origin,
      loadingAll: false,
      infoToken: {
        document: "",
      },
      name: "",
      apps: [],
      listActionMargin: [
        {
          mobileVersion: true,
          icon: origin + "/icon/carga-condiciones-de-comisiones.svg",
          description:
            "Cargue aquí el archivo de condiciones para el pago de comisiones de cada campaña.",
          title: "Condiciones de Comisiones",
          redirect: "uploadMargin",
          externalRedirect: false,
          permisson: false,
          btnName: "Cargar archivo",
          id: 1,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/document-commisions.svg",
          description:
            "Visualiza el acta de divulgación y la tabla de indicadores de comisiones.",
          title: "Acta de comisiones",
          redirect: "commissionsSign",
          externalRedirect: false,
          permisson: false,
          btnName: "Ver acta de comisiones",
          id: 2,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/firma-documento.svg",
          description:
            "Lleve un seguimiento de las personas que hayan firmado el documento de condiciones de comisiones.",
          title: "Control de firmas",
          redirect: "reportSign",
          externalRedirect: false,
          permisson: false,
          btnName: "Control de firmas",
          id: 3,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/receipt-discount.svg",
          description:
            "Visualice aquí la tabla de provisión de comisiones de cada cliente campaña.",
          title: "Provisión de comisiones",
          redirect: "provisionCommissions",
          externalRedirect: false,
          permisson: false,
          btnName: "Provisión de comisiones",
          id: 4,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/receipt-search.svg",
          description:
            "Verifique el estado y el pago de sus comisiones, y gestione sus procesos de reclamación.",
          title: "Reclamaciones",
          redirect: "commissions",
          externalRedirect: false,
          permisson: true,
          btnName: "Reclamaciónes",
          id: 5,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/payroll.svg",
          description: "Descargue sus desprendibles de nómina.",
          title: "Desprendible de nómina",
          redirect: "https://colaborador.brm.com.co/WEBKACTUS/",
          externalRedirect: true,
          permisson: true,
          btnName: "Desprendible de nómina",
          id: 6,
        },
      ],
      name: "Angie",
      token: "",
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
    };
  },
  computed: {
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      if (allApps.message) {
        this.getApps(allApps.data);
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getApps(allApps) {
      console.log(allApps, "apps");
      if (allApps && allApps.length !== 0) {
        let end = allApps.length - 1;
        for (let i = 0; i < allApps.length; i++) {
          if (
            allApps[i].es_un_modulo == "true" &&
            (allApps[i]._ID == "315" ||
              allApps[i]._ID == "316" ||
              allApps[i]._ID == "317" ||
              allApps[i]._ID == "318" ||
              allApps[i]._ID == "326" ||
              allApps[i]._ID == "327" ||
              allApps[i]._ID == "359" ||
              allApps[i]._ID == "467" ||
              allApps[i]._ID == "342") // se quita, solo cuando es admdev
          ) {
            for (let j = 0; j < this.listActionMargin.length; j++) {
              if (allApps[i]._ID == "315") {
                if (
                  this.listActionMargin[j].id == 1 ||
                  this.listActionMargin[j].id == 3 ||
                  this.listActionMargin[j].id == 4
                ) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "318") {
                // TMK
                if (this.listActionMargin[j].id == 2) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "317") {
                // Coordinador
                if (
                  this.listActionMargin[j].id == 2 ||
                  this.listActionMargin[j].id == 3
                ) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "316") {
                // Workforce
                if (this.listActionMargin[j].id == 4) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "326") {
                if (this.listActionMargin[j].id == 7) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "327") {
                if (this.listActionMargin[j].id == 8) {
                  this.listActionMargin[j].permisson = true;
                }
                if (this.listActionMargin[j].id == 12) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "359") {
                if (this.listActionMargin[j].id == 9) {
                  this.listActionMargin[j].permisson = true;
                }
                if (this.listActionMargin[j].id == 10) {
                  this.listActionMargin[j].permisson = true;
                }
                if (this.listActionMargin[j].id == 13) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "467") {
                if (this.listActionMargin[j].id == 11) {
                  this.listActionMargin[j].permisson = true;
                }
              } else if (allApps[i]._ID == "342") {
                // se quita, solo cuando es admdev
                if (this.listActionMargin[j].id == 9) {
                  this.listActionMargin[j].permisson = true;
                }
                if (this.listActionMargin[j].id == 10) {
                  this.listActionMargin[j].permisson = true;
                }
              }
            }
          }
          if (i == end) {
            this.loadingAll = false;
          }
        }
      }
    },
    go(data) {
      if (data.externalRedirect) {
        let newURL = document.createElement("a");
        newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
        newURL.href = data.redirect;
        newURL.target = "_blank";
        document.body.appendChild(newURL);
        newURL.click();
      } else {
        if (data.redirect === "commissionsSign") {
          this.getPdf(data);
        } else {
          this.$router.push({ name: data.redirect });
        }
      }
    },
    getPdf(data) {
      this.loadingAll = true;
      Api.MarginCommisions()
        .getDocumentSign(this.token)
        .then((res) => {
          this.$router.push({ name: data.redirect });
          this.loadingAll = false;
        })
        .catch((error) => {
          // console.log(error.response.status);
          this.loadingAll = false;
          this.message.dialog = true;
          this.message.title = "¡Oh no!";
          this.message.sms = error.response.data.message;
          setTimeout(() => {
            this.message.dialog = false;
          }, 5000);
        });
    },
  },
  created() {
    this.getToken();
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  watch: {
    getAllApps: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";
.navidad-text {
  display: flex;
}

.mb-xl-margin {
  display: block;
}
.mb-sm-margin {
  display: none;
}

.title-margin-action {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #353535;
}
.text-margin-action {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #626262;
  text-align: center !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.requests-card-margin {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.request-project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.request-txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.index-margin__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.index-margin__col-card {
  max-width: 280px !important;
}

.index-margin__card {
  border-radius: 15px !important;
  width: 280px;
}

.index-margin__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}
</style>
